import { AdvancedList, FieldDefinition, Variable } from "@amzn/altar-sds-client";
import {
    Action,
    Condition,
    FieldConfiguration,
    Workflow
} from "@amzn/ask-legal-domain";
import {
    FormField,
    FormFieldProps,
    Header,
    Input,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { UIField } from "../../../../common/UIField";
import { EmailNotification } from "./EmailNotification";

export const ItemFieldValueChange = (props: {
    advancedList: AdvancedList;
    variables: Variable[];
    fieldConfigurations?: FieldConfiguration.Record;
    value?: Workflow.Payload;
    onUpdated?: (value: Workflow.Payload) => void;
}) => {
    const condition = props.value?.condition as Condition.ItemFieldValueChanged;
    const fieldDefinitions: FieldDefinition[] = props.advancedList?.fieldDefinitions ?? [];

    const [formFieldWorkflowName, setFormFieldWorkflowName] = React.useState<FormFieldProps>({});
    const [workflowName, setWorkflowName] = React.useState<string>(props.value?.name ?? "");

    const [formFieldColumnField, setFormFieldColumnField] = React.useState<FormFieldProps>({});
    const [selectedField, setSelectedField] = React.useState<FieldDefinition | undefined>(
        props.advancedList.fieldDefinitions?.find((field) => field.fieldKey === condition?.fieldKey) ??
            undefined
    );

    const [emailNotification, setEmailNotification] = React.useState<EmailNotification>({
        personFieldKey: props.value?.action.personFieldKey || "",
        emailSubject: props.value?.action.subject || "",
        emailBody: props.value?.action.body || ""
    });

    React.useEffect(() => {
        let valid = true;
        if (!workflowName?.trim().length) {
            setFormFieldWorkflowName({
                errorText: "Workflow Name is required"
            });
            valid = false;
        } else {
            setFormFieldWorkflowName({
                errorText: ""
            });
        }

        if (!selectedField?.fieldKey) {
            setFormFieldColumnField({
                errorText: "Field selection is required"
            });
            valid = false;
        } else {
            setFormFieldColumnField({
                errorText: ""
            });
        }

        if (
            !(
                emailNotification?.personFieldKey?.trim().length &&
                emailNotification?.emailSubject?.trim().length &&
                emailNotification?.emailBody?.trim().length
            )
        ) {
            valid = false;
        }

        if (!valid) {
            console.warn(`not a valid data`, workflowName, selectedField, emailNotification);
            return;
        }

        const condition = new Condition.ItemFieldValueChanged(selectedField?.fieldKey!);

        const action = new Action.EmailToPersonFieldValue(
            emailNotification!.emailSubject!.trim(),
            emailNotification!.emailBody!.trim(),
            emailNotification!.personFieldKey!.trim()
        );

        const workflowPayload = Workflow.Payload.init({
            id: props.value?.id,
            name: workflowName.trim(),
            conditionType: Condition.Type.ItemFieldValueChanged,
            condition,
            action,
            actionType: Action.Type.EmailToPersonFieldValue,
            ref: props.value?.ref,
            variables: props.variables,
            disabled: props.value?.disabled
        });

        props.onUpdated?.(workflowPayload);
    }, [workflowName, selectedField, emailNotification]);

    return (
        <SpaceBetween size={"s"}>
            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Name</Header>
                <FormField errorText={formFieldWorkflowName.errorText}>
                    <Input value={workflowName} onChange={(e) => setWorkflowName(e.detail.value)} />
                </FormField>
            </SpaceBetween>

            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Criteria</Header>
                <UIField.CustomField
                    name="Field Selection"
                    description="Choose Field on which you want to set a workflow"
                    errorText={formFieldColumnField.errorText}
                    child={
                        <StaticListSelect.Single<FieldDefinition>
                            data={fieldDefinitions}
                            selected={selectedField}
                            onSelectChange={(selectedFieldDefintion: FieldDefinition) => {
                                setSelectedField(selectedFieldDefintion);
                            }}
                            fromOption={(option: SelectProps.Option) => {
                                return fieldDefinitions.find(
                                    (field) => field.fieldKey === option.value!
                                )! as FieldDefinition;
                            }}
                            toOption={(fieldDefinition: FieldDefinition) => {
                                return {
                                    label: fieldDefinition?.displayName!,
                                    value: fieldDefinition?.fieldKey!
                                };
                            }}
                        />
                    }
                />
            </SpaceBetween>

            <EmailNotification
                fieldDefinitions={props.advancedList.fieldDefinitions ?? []}
                emailNotification={emailNotification}
                fieldConfigurations={props.fieldConfigurations}
                workflowNotificationType={Condition.Type.ItemFieldValueChanged}
                onChanged={(value: EmailNotification) => {
                    setEmailNotification(value);
                }}
                variables={props.value?.variables || props.variables}
            />
        </SpaceBetween>
    );
};
