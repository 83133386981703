import {
    AdvancedList,
    ChoiceOption,
    FieldDefinition,
    FieldDefinitionDataType,
    Variable
} from "@amzn/altar-sds-client";
import {
    Action,
    Condition,
    FieldConfiguration,
    Workflow
} from "@amzn/ask-legal-domain";
import {
    FormField,
    FormFieldProps,
    Header,
    Input,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { UIField } from "../../../../common/UIField";
import { EmailNotification } from "./EmailNotification";

export const ItemFieldValueMatch = (props: {
    advancedList: AdvancedList;
    variables: Variable[];
    fieldConfigurations?: FieldConfiguration.Record;
    value?: Workflow.Payload;
    onUpdated?: (value: Workflow.Payload) => void;
}) => {
    const condition = props.value?.condition as Condition.ItemChoiceFieldValueMatched;
    const singleChoiceSelectFields: FieldDefinition[] = [];
    props.advancedList?.fieldDefinitions
        ?.filter((def) => def.dataType === FieldDefinitionDataType.choice)
        .forEach((def) => {
            if (
                (props.fieldConfigurations?.[def.fieldKey!] as FieldConfiguration.Choice)?.type === FieldConfiguration.ChoiceType.SINGLE
            ) {
                singleChoiceSelectFields.push(def);
            }
        });

    const [formFieldWorkflowName, setFormFieldWorkflowName] = React.useState<FormFieldProps>({});
    const [workflowName, setWorkflowName] = React.useState<string>(props.value?.name ?? "");

    const [formFieldColumnField, setFormFieldColumnField] = React.useState<FormFieldProps>({});
    const [selectedField, setSelectedField] = React.useState<FieldDefinition | undefined>(
        singleChoiceSelectFields.find((field) => field.fieldKey === condition?.fieldKey) ?? undefined
    );

    const [formFieldChoiceOption, setFormFieldChoiceOption] = React.useState<FormFieldProps>({});
    const [selectedChoiceOption, setSelectedChoiceOption] = React.useState<ChoiceOption | undefined>(
        selectedField?.choiceOptions?.find((option) => option.key === condition?.expectedValue) ?? undefined
    );

    const [emailNotification, setEmailNotification] = React.useState<EmailNotification>({
        personFieldKey: props.value?.action.personFieldKey || "",
        emailSubject: props.value?.action.subject || "",
        emailBody: props.value?.action.body || ""
    });

    React.useEffect(() => {
        let valid = true;
        if (!workflowName?.trim().length) {
            setFormFieldWorkflowName({
                errorText: "Workflow Name is required"
            });
            valid = false;
        } else {
            setFormFieldWorkflowName({
                errorText: ""
            });
        }

        if (!selectedField?.fieldKey) {
            setFormFieldColumnField({
                errorText: "Field selection is required"
            });
            valid = false;
        } else {
            setFormFieldColumnField({
                errorText: ""
            });
        }

        if (!selectedChoiceOption?.key) {
            setFormFieldChoiceOption({
                errorText: "Choice selection is required"
            });
            valid = false;
        } else {
            setFormFieldChoiceOption({
                errorText: ""
            });
        }

        if (
            !(
                emailNotification?.personFieldKey?.trim().length &&
                emailNotification?.emailSubject?.trim().length &&
                emailNotification?.emailBody?.trim().length
            )
        ) {
            valid = false;
        }

        if (!valid) {
            console.warn(`not a valid data`, workflowName, selectedField, selectedChoiceOption, emailNotification);
            return;
        }

        const condition = new Condition.ItemChoiceFieldValueMatched(selectedField?.fieldKey!, selectedChoiceOption.key);

        const action = new Action.EmailToPersonFieldValue(
            emailNotification!.emailSubject!.trim(),
            emailNotification!.emailBody!.trim(),
            emailNotification!.personFieldKey!.trim()
        );

        const workflowPayload = Workflow.Payload.init({
            id: props.value?.id,
            name: workflowName.trim(),
            conditionType: Condition.Type.ItemChoiceFieldValueMatched,
            condition,
            action,
            actionType: Action.Type.EmailToPersonFieldValue,
            ref: props.value?.ref,
            variables: props.variables,
            disabled: props.value?.disabled
        });

        props.onUpdated?.(workflowPayload);
    }, [workflowName, selectedField, selectedChoiceOption, emailNotification]);

    return (
        <SpaceBetween size={"s"}>
            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Name</Header>
                <FormField errorText={formFieldWorkflowName.errorText}>
                    <Input value={workflowName} onChange={(e) => setWorkflowName(e.detail.value)} />
                </FormField>
            </SpaceBetween>

            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Criteria</Header>
                <UIField.CustomField
                    name="Field Selection"
                    description="Choose Field on which you want to set a workflow"
                    errorText={formFieldColumnField.errorText}
                    child={
                        <StaticListSelect.Single<FieldDefinition>
                            data={singleChoiceSelectFields}
                            selected={selectedField}
                            onSelectChange={(selectedFieldDefintion: FieldDefinition) => {
                                setSelectedField(selectedFieldDefintion);
                            }}
                            fromOption={(option: SelectProps.Option) => {
                                return singleChoiceSelectFields.find((field) => field.fieldKey === option.value!)!;
                            }}
                            toOption={(fieldDefinition: FieldDefinition) => {
                                return {
                                    label: fieldDefinition?.displayName!,
                                    value: fieldDefinition?.fieldKey!
                                };
                            }}
                        />
                    }
                />

                {selectedField && (
                    <UIField.CustomField
                        name="Choice Selection"
                        description="Choose choice value on which you want to start a workflow"
                        errorText={formFieldChoiceOption.errorText}
                        child={
                            <StaticListSelect.Single<ChoiceOption>
                                data={selectedField?.choiceOptions ?? []}
                                selected={selectedChoiceOption}
                                onSelectChange={(selectedChoiceOption: ChoiceOption) => {
                                    setSelectedChoiceOption(selectedChoiceOption);
                                }}
                                fromOption={(option: SelectProps.Option) => {
                                    return (
                                        singleChoiceSelectFields.find(
                                            (field) => field.fieldKey === selectedField.fieldKey!
                                        )!.choiceOptions ?? []
                                    ).find((o) => o.key === option.value!)!;
                                }}
                                toOption={(choiceOption: ChoiceOption) => {
                                    return {
                                        label: choiceOption?.displayValue!,
                                        value: choiceOption?.key!
                                    };
                                }}
                            />
                        }
                    />
                )}
            </SpaceBetween>

            <EmailNotification
                fieldDefinitions={props.advancedList.fieldDefinitions ?? []}
                emailNotification={emailNotification}
                fieldConfigurations={props.fieldConfigurations}
                workflowNotificationType={Condition.Type.ItemChoiceFieldValueMatched}
                onChanged={(value: EmailNotification) => {
                    setEmailNotification(value);
                }}
                variables={props.value?.variables || props.variables}
            />
        </SpaceBetween>
    );
};
