import { ALView, PrimitiveDataFactory } from "@amzn/ask-legal-domain";
import { AdvancedList, ReservedFieldKey } from "@amzn/altar-sds-client";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Textarea
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ItemPickerSorter } from "../../../../common/ItemPickerSorter";

export interface ViewAddEditModalProps {
  advancedList: AdvancedList;
  viewPayload?: ALView.Payload;
  onSubmit?: (value: ALView.Payload) => void;
  onCancel?: () => void;
}

export const ViewAddEditModal = (props: ViewAddEditModalProps) => {
  const [viewPayload, setViewPayload] = React.useState<ALView.Payload>(
    props.viewPayload ?? ALView.Payload.init({
      id: PrimitiveDataFactory.id(4),
      name: "",
      description: "",
      fieldKeys: [
        ReservedFieldKey.ID
      ],
    })
  );

  return <Modal
    visible
    size="large"
    header={<Header variant="h3">Configure View</Header>}
    onDismiss={() => props.onCancel?.()}
    closeAriaLabel="Close modal"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="link"
            onClick={() => props.onCancel?.()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => props.onSubmit?.(viewPayload)}
            disabled={ !viewPayload.name || !viewPayload.fieldKeys.length }
          >
            Save
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <Form>
      <SpaceBetween size="m">
        <FormField label="Name" errorText={!viewPayload.name.length && "Required"}>
          <Input
            value={viewPayload.name}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                name: e.detail.value
              });
            }}
            placeholder="Enter view name"
          />
        </FormField>
        <FormField label="Description">
          <Textarea
            value={viewPayload.description}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                description: e.detail.value
              });
            }}
            placeholder="Enter brief description of view"
          />
        </FormField>
        <FormField label="Visible Fields and Order" errorText={!viewPayload.fieldKeys.length && "Cannot be empty"}>
          <ItemPickerSorter
            availableItems={props.advancedList.fieldDefinitions
              .filter(f => !f.deprecated && !viewPayload.fieldKeys.includes(f.fieldKey))
              .map(f => ({ id: f.fieldKey, content: f.displayName }))
            }
            selectedItems={viewPayload.fieldKeys.map((key) => ({
              id: key,
              content: props.advancedList.fieldDefinitions?.find(f => f.fieldKey === key)?.displayName ?? key
            }))}
            onSelectedItemsChange={(items) => {
              setViewPayload({
                ...viewPayload,
                fieldKeys: items.map(i => i.id)
              });
            }}
            options={{
              nonDeleteableIds: [ReservedFieldKey.ID]
            }}
          />
        </FormField>
        <FormField label="Filter">
          <Textarea
            value={viewPayload.filter}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                filter: e.detail.value
              });
            }}
            placeholder="Default Filter"
          />
        </FormField>
        <FormField label="Sort">
          <Textarea
            value={viewPayload.sort}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                sort: e.detail.value
              });
            }}
            placeholder="Default Sort"
          />
        </FormField>
      </SpaceBetween>
    </Form>
  </Modal>;
};